import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const closeMenu = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="bg-white p-4 text-black shadow-xl">
      <div className="container mx-auto flex justify-between items-center">
        <div className="text-red text-2xl font-bold">
          <Link to="/">Double XL</Link>
        </div>

        {/* Desktop menu */}
        <ul className="hidden md:flex space-x-6">
          <li>
            <Link to="/" className="text-black hover:text-green-500">
              Home
            </Link>
          </li>
          <li>
            <Link to="/about" className="text-black hover:text-green-500">
              About
            </Link>
          </li>
          <li>
            <Link to="/product" className="text-black hover:text-green-500">
              Products
            </Link>
          </li>
          <li>
            <Link to="/contact" className="text-black hover:text-green-500">
              Contact
            </Link>
          </li>
        </ul>

        {/* Hamburger menu for mobile */}
        <div className="md:hidden flex items-center">
          <button
            onClick={toggleMenu}
            className={`text-black focus:outline-none relative z-20 transition-all duration-300 ${
              isMobileMenuOpen ? 'rotate-45' : ''
            }`} // Rotate hamburger when clicked
          >
            <div
              className={`w-6 h-1 bg-black rounded-full transition-all duration-300 transform ${
                isMobileMenuOpen ? 'rotate-45 translate-y-2.5' : ''
              }`}
            ></div>
            <div
              className={`w-6 h-1 bg-black rounded-full my-1 transition-all duration-300 ${
                isMobileMenuOpen ? 'opacity-0' : ''
              }`}
            ></div>
            <div
              className={`w-6 h-1 bg-black rounded-full transition-all duration-300 transform ${
                isMobileMenuOpen ? 'rotate--45 -translate-y-2.5' : ''
              }`}
            ></div>
          </button>
        </div>
      </div>

      {/* Full-screen mobile menu */}
      <div
        className={`${
          isMobileMenuOpen ? 'block' : 'hidden'
        } fixed inset-0 bg-black bg-opacity-60 z-50 md:hidden transition-all duration-500 ease-in-out transform ${
          isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        onClick={closeMenu} // Close menu when clicking outside the menu
      >
        <div
          className="bg-white w-full h-full p-6 flex flex-col justify-center items-center relative"
          onClick={(e) => e.stopPropagation()} // Prevent click event from closing the menu when inside
        >
          {/* Close button (cross) */}
          <button
            onClick={closeMenu}
            className="absolute top-4 right-4 text-2xl text-black"
          >
            &times;
          </button>

          <ul className="space-y-6 text-xl text-center">
            <li>
              <Link to="/" className="block text-black hover:text-green-500" onClick={closeMenu}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="block text-black hover:text-green-500" onClick={closeMenu}>
                About
              </Link>
            </li>
            <li>
              <Link to="/product" className="block text-black hover:text-green-500" onClick={closeMenu}>
                Products
              </Link>
            </li>
            <li>
              <Link to="/contact" className="block text-black hover:text-green-500" onClick={closeMenu}>
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
