import React, { useRef } from "react";
import xxl from "./image/xxl.png"; // Correct image path
import banner from "../navdown/website banner (2).jpg";

const Product = () => {
  const cardRefs = useRef([]);

  const products = [
    {
      id: 1,
      name: "Double XL Washing Powder",
      image: xxl,
      description: "Cleans clothes with deep-clean technology and fresh scent.",
      usp: ["Fresh Scent", "Deep Cleaning", "Eco-Friendly"],
      quantity: "500 gm",
    },
    {
      id: 2,
      name: "Eco-Friendly Softener",
      image: xxl,
      description: "Provides long-lasting softness and smooth fabric feel.",
      usp: ["Softening", "Eco-friendly", "Long-lasting"],
      quantity: "1 KG",
    },
    {
      id: 3,
      name: "Power Bleach",
      image: xxl,
      description: "Powerful bleach for tough stains and bright whites.",
      usp: ["Whitening", "Stain Removal", "Safe for Colors"],
      quantity: "3 kg",
    },
    {
      id: 4,
      name: "Power Bleach",
      image: xxl,
      description: "Powerful bleach for tough stains and bright whites.",
      usp: ["Whitening", "Stain Removal", "Safe for Colors"],
      quantity: "140 gm",
    },
    {
      id: 5,
      name: "Power Bleach",
      image: xxl,
      description: "Powerful bleach for tough stains and bright whites.",
      usp: ["Whitening", "Stain Removal", "Safe for Colors"],
      quantity: "2.700 kg",
    },
  ];

  return (
    <div className="w-full">
      <>
        {/* Banner Section */}
        <div className="w-full h-[30rem]">
          <img src={banner} className="w-full h-full object-cover" alt="Banner" />
        </div>

        {/* Product Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-6">
          {products.map((product, index) => (
            <div
              key={product.id}
              ref={(el) => (cardRefs.current[index] = el)}
              className="bg-gradient-to-br from-gray-50 to-gray-100 border border-gray-200 rounded-xl shadow-lg transform hover:-translate-y-3 transition-transform duration-300 hover:shadow-2xl"
            >
              {/* Product Image */}
              <img
                src={product.image}
                alt={product.name}
                className="w-full h-48 object-cover rounded-t-lg"
              />

              {/* Product Details */}
              <div className="p-4">
                <h3 className="text-lg font-semibold text-gray-800">{product.name}</h3>
                <p className="text-sm text-gray-600 mt-2">{product.description}</p>

                {/* USP Icons */}
                <div className="flex flex-wrap gap-2 mt-4">
                  {product.usp.map((feature, index) => (
                    <span
                      key={index}
                      className="text-xs bg-blue-100 text-blue-600 px-3 py-1 rounded-full"
                    >
                      {feature}
                    </span>
                  ))}
                </div>

                {/* Quantity Section */}
                <div className="mt-4">
                  <span className="text-sm font-medium text-gray-700">Quantity:</span>{" "}
                  <span className="text-sm text-gray-600">{product.quantity}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default Product;
