import React from "react";
import UniversityPage from "./screen/home";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { IoLogoWhatsapp } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import AboutSection from "./screen/about";
import ContactSection from "./screen/contact";
import Product from "./screen/home/product";
import Home from "./screen/home";
import Navbar from "./screen/home/navbar";
import Footer2 from "./screen/footer2";
import PrivacyPolicy from "./screen/privacyPolicy";



const App = () => {


  return (
    <div className="overflow-hidden">
      <Router>
      <Navbar/>
        <Routes>
          {/* Route for the home page */}
          <Route path="/" element={<Home/>} />
          <Route path="/about" element={<AboutSection/>} />
          <Route path="/contact" element={<ContactSection/>} />
          <Route path="/product" element={<Product/>} />
          <Route path="/privacy" element={<PrivacyPolicy/>} />
          
          {/* Route for the enquiry form */}
          
        </Routes>
    
        {/* <Footer  /> */}
        {/* <Disclaimer /> */}

        <div className="fixed bottom-0 text-[30px] right-0 gap-3 px-8 py-6 z-[9999]">
          <div className="p-3 bg-gray-300 mb-3 rounded-full">
            <a href="tel:+917425886832">
              <IoCall className="text-blue-500" />
            </a>
          </div>
          <div className="p-3 bg-gray-300 mb-2 rounded-full">
            <a href="https://wa.me/+917425886832">
              <IoLogoWhatsapp className="text-green-500" />
            </a>
          </div>
        </div>
        <Footer2/>
      
      </Router>
    </div>
  );
};

export default App;
