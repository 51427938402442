import React from 'react';
import { Link } from 'react-router-dom';

const Footer2 = () => {
  return (
    <footer className="bg-[#001e7e] text-white py-10">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-gray-300">Company</h3>
            <ul className="space-y-2">
              <li><a href="#about" className="hover:text-blue-400 transition duration-300">About Us</a></li>
              <li className="hover:text-blue-400 transition duration-300"> <Link to="/privacy">Privacy Policy</Link></li>
            </ul>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-gray-300">Quick Links</h3>
            <ul className="space-y-2">
              <li><Link to="/contact" className="hover:text-blue-400 transition duration-300">Contact Us</Link></li>
            </ul>
          </div>

          {/* Follow Us */}
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-gray-300">Follow Us</h3>
            <ul className="space-y-2">
              <li><a href="https://www.facebook.com/profile.php?id=61566883863154&sk=videos" className="hover:text-blue-400 transition duration-300" target="_blank" rel="noopener noreferrer">Facebook</a></li>
              <li><a href="https://instagram.com/doublexlindia" className="hover:text-blue-400 transition duration-300" target="_blank" rel="noopener noreferrer">Instagram</a></li>
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-2xl font-semibold mb-4 text-gray-300">Contact Info</h3>
            <ul className="space-y-2">
              <li><a href="mailto:doublexlindia@gmail.com" className="hover:text-blue-400 transition duration-300">doublexlindia@gmail.com</a></li>
              <li><a href="tel:+917425886832" className="hover:text-blue-400 transition duration-300">+91 7425886832</a></li>
              <li><p className="text-sm text-gray-400">Behind Truck Union, Dausa, Rajasthan</p></li>
            </ul>
          </div>
        </div>

        {/* Footer Bottom Section */}
        <div className="border-t border-gray-600 mt-8 pt-6 text-center text-sm text-gray-400">
          <p> MADE IN INDIA ❤️ &copy; 2024 DOUBLE XL. All rights reserved. Crafted with ❤️ for quality products.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer2;
