import React, { useEffect, useState } from 'react';
import { FaTshirt, FaRecycle, FaHandsHelping, FaSmile, FaLeaf, FaLaugh } from 'react-icons/fa'; // More engaging icons

const BannerSlider = () => {
  // Updated specifications with more engaging content in Hindi
  const specifications = [
    { text: "10 मिनट में जिद्दी दागों को साफ करें - आपका नया सुपरहीरो!", icon: <FaHandsHelping color="#4CAF50" /> },
    { text: "पावरफुल फार्मूला - दागों की छुट्टी!", icon: <FaTshirt color="#FF5722" /> },
    { text: "कपड़ों को नरम, गंदगी को सख्त तरीके से हटा दे", icon: <FaSmile color="#FFEB3B" /> },
    { text: "एक ही धोने में दाग जाए, ताजगी आए!", icon: <FaRecycle color="#00BCD4" /> },
    { text: "कुदरती, पर्यावरण के अनुकूल सामग्री", icon: <FaLeaf color="#8BC34A" /> },
    { text: "दाग हटाने का आसान तरीका - सिर्फ़ 10 मिनट!", icon: <FaLaugh color="#FFC107" /> },
    { text: "ऑल-राउंडer: कपड़े को मुलायम, गंदगी को सख्त", icon: <FaTshirt color="#9C27B0" /> },
    { text: "सस्ती और भरोसेमंद - दिल से सच्चा!", icon: <FaHandsHelping color="#673AB7" /> },
  ];

  const [scrollIndex, setScrollIndex] = useState(0);
  const itemsPerView = 1; // 1 per view for better readability

  // Infinite scrolling effect
  useEffect(() => {
    const scrollEffect = setInterval(() => {
      setScrollIndex((prevIndex) => (prevIndex === specifications.length - 1 ? 0 : prevIndex + 1));
    }, 3000); // Change interval to 3 seconds for a smoother experience

    return () => clearInterval(scrollEffect);
  }, [specifications.length]);

  // Function to handle manual navigation
  const handleNext = () => {
    setScrollIndex((prevIndex) => (prevIndex === specifications.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div className="flex items-center justify-center bg-[#011c74]  shadow-md">
      <div className="relative w-full max-w-full overflow-hidden">
        
        {/* Course Slider */}
        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${scrollIndex * (100 / itemsPerView)}%)` }}
        >
          {specifications.map((spec, index) => (
            <div
              key={index}
              className="flex-shrink-0 w-full py-4 text-white flex items-center justify-center text-lg md:text-xl font-bold"
            >
              <div className="flex items-center justify-center space-x-4">
                <div className="text-4xl">{spec.icon}</div>
                <div className="text-center">{spec.text}</div>
              </div>
            </div>
          ))}
        </div>

        {/* Next Button */}
        <button
          onClick={handleNext}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 text-blue-700 p-2 rounded-r"
        >
          &#8594;
        </button>
      </div>
    </div>
  );
};

export default BannerSlider;
