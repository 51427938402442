import React from 'react';

const ContactSection = () => {
  return (
    <section className="py-16 bg-white text-gray-800">
      <div className="max-w-6xl mx-auto text-center">
        <h2 className="text-3xl font-bold mb-4">Contact Us</h2>
        <p className="text-lg mb-6">
          If you have any queries, want to become a distributor, or just want to know more about our products, 
          reach out to us! We're here to assist you with all the information you need.
        </p>
        
        {/* Contact Form */}
        <form className="bg-gray-100 p-6 rounded-lg shadow-md">
          <input type="text" placeholder="Full Name" className="w-full mb-4 p-3 rounded-md border-2 border-gray-300" />
          <input type="email" placeholder="Email Address" className="w-full mb-4 p-3 rounded-md border-2 border-gray-300" />
          <textarea placeholder="Message" className="w-full mb-4 p-3 rounded-md border-2 border-gray-300" rows="4"></textarea>
          
          <button className="bg-teal-500 text-white py-3 px-6 rounded-md">Send Message</button>
        </form>
        
        <div className="mt-8 text-lg">
          <p>For Distributor Inquiries:</p>
          <p>Call us at: <strong>+91 7425886832</strong></p>
          <p>Email us at: <strong>doublexlindia@gmail.com</strong></p>
        </div>
      </div>
    </section>
  );
};

export default ContactSection;
