import React, { useEffect, useRef, useState } from "react";
import gsap from "gsap";

import xxl from "../product/image/xxl.png"; // Ensure correct image path

const DynamicProductShowcase = () => {
  const containerRef = useRef(null);
  const productRefs = useRef([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  // Sample products with details
  const products = [
    {
      id: 1,
      name: "Detergent",
      image: xxl,
      quantity: "500 gm",
      description: "इस डिटर्जेंट से आपके कपड़े चमकते और ताजगी से भरपूर रहेंगे।",
      benefits: [
        "दाग को पूरी तरह से हटाता है।",
        "मुलायम कपड़ों के लिए उपयुक्त।",
        "ताजगी का एहसास बनाए रखता है।",
      ],
    },
    {
      id: 2,
      name: "Fabric Softener",
      image: xxl,
      quantity: "1 kg",
      description: "फैब्रिक सॉफ़्टनर आपके कपड़ों को बेहद मुलायम बनाता है।",
      benefits: [
        "कपड़ों की गुणवत्ता बनाए रखता है।",
        "हर धुलाई के साथ बेहतर खुशबू।",
        "आसानी से इस्त्री करने लायक बनाता है।",
      ],
    },
    {
      id: 3,
      name: "Bleach",
      image: xxl,
      quantity: "2.7 kg",
      description: "यह ब्लीच सफेद कपड़ों को बेहद सफ़ेद बनाए रखने में मदद करता है।",
      benefits: [
        "सफेद कपड़ों के लिए विशेष रूप से डिज़ाइन।",
        "दाग-धब्बों को तुरंत हटाता है।",
        "कीटाणुओं से सुरक्षा प्रदान करता है।",
      ],
    },
    {
      id: 4,
      name: "Stain Remover",
      image: xxl,
      quantity: "3 kg",
      description: "यह उत्पाद जिद्दी दागों को भी हटा देता है।",
      benefits: [
        "तेल और चाय के दागों को भी प्रभावी ढंग से हटाता है।",
        "कपड़ों पर कोमलता बनाए रखता है।",
        "हर प्रकार के कपड़ों के लिए उपयुक्त।",
      ],
    },
    {
      id: 5,
      name: "Laundry Pods",
      image: xxl,
      quantity: "140 gm",
      description: "लॉन्ड्री पॉड्स का उपयोग करना सरल और प्रभावी है।",
      benefits: [
        "उचित मात्रा में सफाई के लिए पहले से तैयार।",
        "मजबूत और मुलायम कपड़ों दोनों के लिए उपयुक्त।",
        "ताजगी की लम्बी अवधि बनाए रखता है।",
      ],
    },
  ];

  useEffect(() => {
    const radius = 180; // Circle radius
    const angleStep = (2 * Math.PI) / products.length;

    productRefs.current.forEach((product, index) => {
      const angle = index * angleStep;
      gsap.to(product, {
        duration: 1.2,
        x: radius * Math.cos(angle),
        y: radius * Math.sin(angle),
        opacity: 1,
        ease: "power3.out",
        delay: index * 0.2,
      });
    });
  }, [products]);

  const handleProductClick = (index) => {
    setSelectedProduct(index);

    productRefs.current.forEach((product, i) => {
      if (i === index) {
        gsap.to(product, {
          duration: 0.8,
          scale: 1.4,
          zIndex: 10,
          filter: "drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.3))",
        });
      } else {
        gsap.to(product, {
          duration: 0.8,
          scale: 0.9,
          opacity: 0.5,
        });
      }
    });
  };

  return (
    <div
      ref={containerRef}
      className="relative w-full h-[600px] flex justify-center items-center overflow-hidden bg-[#fcfbf5]"
    >
      {/* Product Showcasing */}
      {products.map((product, index) => (
        <div
          key={product.id}
          ref={(el) => (productRefs.current[index] = el)}
          className="absolute w-36 h-36 bg-[#001c75] rounded-full shadow-lg cursor-pointer transform transition-all hover:scale-110 hover:shadow-2xl"
          onClick={() => handleProductClick(index)}
        >
          <img
            src={product.image}
            alt={product.name}
            className="w-full h-full object-cover rounded-full"
          />
          <div className="absolute bottom-0 w-full text-center bg-[#001c75] text-white text-sm rounded-b-full py-1">
            <p>{product.name}</p>
            <p className="text-xs text-gray-300">{product.quantity}</p>
          </div>
        </div>
      ))}

      {selectedProduct !== null && (
        <div
          className={`absolute bg-white shadow-2xl rounded-lg p-4 w-80 ${
            selectedProduct % 2 === 0 ? "left-10" : "right-10"
          } top-20 md:top-[25%] lg:top-[10%] z-20`}
        >
          <h2 className="text-xl font-bold text-gray-800">
            {products[selectedProduct]?.name}
          </h2>
          <p className="text-sm text-gray-600 mt-2">
            {products[selectedProduct]?.description}
          </p>
          <ul className="list-disc ml-5 mt-2 text-sm text-gray-600">
            {products[selectedProduct]?.benefits.map((benefit, idx) => (
              <li key={idx}>{benefit}</li>
            ))}
          </ul>
          <button
            className="mt-4 px-4 py-2 bg-gradient-to-r from-[#001c75] to-[#4682b4] text-white rounded-lg"
            onClick={() => setSelectedProduct(null)}
          >
            बंद करें
          </button>
        </div>
      )}
    </div>
  );
};

export default DynamicProductShowcase;
