import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-[100%] flex justify-center">
      <div className="bg-white p-6 rounded-lg shadow-2xl w-[87%] my-12">
        <h1 className="text-2xl font-bold mb-8 mt-4 text-center">
          Privacy Policy - Double XL Washing Powder
        </h1>

        <div className="blog-content">
          <h2 className="font-bold mt-4">Introduction</h2>
          <p>
            Welcome to Double XL Washing Powder! Your privacy is important to us, and we are committed to protecting the personal information you share with us. This privacy policy explains how we collect, use, and safeguard your data when you visit our website, make a purchase, or engage with our brand.
          </p>
          
          <h3 className="text-md font-bold mt-4">1. Information Collected</h3>
          <p>
            We collect personal information when you visit our website or purchase Double XL Washing Powder products. This may include your name, email address, phone number, shipping address, and payment details. We may also gather non-personal data such as your browsing behavior, IP address, and device information to improve your experience.
          </p>
          
          <h3 className="text-md font-bold mt-4">2. Cookies and Tracking Technologies</h3>
          <p>
            Our website uses cookies and other tracking technologies to personalize your shopping experience, remember your preferences, and analyze website performance. Cookies are small text files placed on your device when you visit our site. You can manage cookie preferences in your browser settings, but disabling cookies may impact certain features of the website.
          </p>
          
          <h3 className="text-md font-bold mt-4">3. Use of Information</h3>
          <p>
            We use the information we collect to process your orders, enhance your shopping experience, offer personalized recommendations, and provide customer support. Additionally, your information may be used for marketing and promotional purposes, such as sending you product updates and special offers.
          </p>
          
          <h3 className="text-md font-bold mt-4">4. Disclosure of Information</h3>
          <p>
            We respect your privacy and will not share your personal information with third parties, except when necessary to complete your transactions (e.g., payment processors, delivery services) or comply with legal obligations. We may also share your data with trusted partners who help us manage our operations.
          </p>
          
          <h3 className="text-md font-bold mt-4">5. Data Security</h3>
          <p>
            We implement industry-standard security measures to protect your personal data from unauthorized access, loss, or alteration. While we take reasonable precautions to ensure the safety of your information, please note that no data transmission over the internet is 100% secure.
          </p>
          
          <h3 className="text-md font-bold mt-4">6. Your Rights</h3>
          <p>
            You have the right to access, update, and delete your personal information stored with us. If you wish to exercise these rights or have any questions about the data we hold, please contact our support team at <a href="mailto:support@doublexl.com" className="hover:text-blue-400">support@doublexl.com</a>.
          </p>
          
          <h2 className="font-bold mt-4">Changes to this Privacy Policy</h2>
          <p>
            Double XL Washing Powder reserves the right to update this privacy policy as needed. Any changes will be posted on our website, and the updated policy will be effective immediately upon posting.
          </p>
          
          <h2 className="font-bold mt-4">Conclusion</h2>
          <p>
            We take your privacy seriously and strive to protect the information you share with us. If you have any concerns or questions about our privacy practices, feel free to contact us at <a href="mailto:support@doublexl.com" className="hover:text-blue-400">support@doublexl.com</a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
