import React from "react";
import HomeBanner from "./homeBanner";
import BannerSlider from "./bannerslider";
import Product from "./product";
import MovingProduct from "./movingProduct";
import DynamicProductShowcase from "./movingProduct";
import FeatureShowcase from "./company";
import Company from "./company";
import Navbar from "./navbar";
import AdditionalContent from "./extracontent";

import Navdown from "./navdown";
// import AirplaneProductShowcase from "./airplaneProduct";
// import DoubleXLProductShowcase from "./airplaneProduct";
// import BubbleFrenzy from "./airplaneProduct";
// import FreshnessCascade from "./airplaneProduct";
// import WashingMachineAnimation from "./washingAnimation";
// import ContactSection from "../contact";
// import AboutSection from "../about";







function Home() {
  return (
    <div className="w-full overflow-hidden ">
      

 <Navdown/>
   
    {/* <AirplaneProductShowcase/> */}
 
     <DynamicProductShowcase/>
     <BannerSlider/>
     <HomeBanner/>
     <AdditionalContent/>
     {/* <Product/> */}
     <Company/> 
    
      
      


    </div>
  );
}

export default Home;
