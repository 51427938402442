import React from 'react';
import img1 from "./founder.png";
import img2 from "./md.png"
import img3 from "./ceo3.jpg"

const AboutSection = () => {
  return (
    <section className="py-16 bg-gradient-to-r from-teal-400 via-indigo-500 to-purple-600 text-white">
      <div className="max-w-6xl mx-auto text-center">
        {/* Title */}
        <h2 className="text-4xl font-extrabold text-yellow-300 mb-6">Discover the Magic of Double XL Washing Powder</h2>
        
        {/* Brand Story */}
        <p className="text-lg mb-6">
          Born in the heart of Uttar Pradesh, Double XL Washing Powder is not just a brand – it's a revolution in the laundry world! 
          We know that every stain tells a story, and that’s why we crafted a super-powered formula that tackles even the toughest stains in just 10 minutes.
          Our mission? To bring you the freshest clothes, effortlessly, while protecting the environment with every wash. Double XL is a superhero for your laundry, providing unbeatable cleaning without harming the planet.
        </p>
        <p className="text-lg mb-6">
          Our product is made from eco-friendly ingredients that are safe for both your fabrics and nature. Whether you're hand-washing or using a machine, Double XL delivers unbeatable results with minimal effort. 
          Let us help you say goodbye to stubborn stains and hello to clean, fresh clothes with every wash.
        </p>

        {/* Mission Statement */}
        <div className="mt-12">
          <h3 className="text-3xl font-bold text-yellow-400 mb-4">Our Mission</h3>
          <p className="text-lg">
            At Double XL, we aim to revolutionize the way India thinks about laundry. Our mission is simple: 
            to provide a laundry solution that is both effective and sustainable. By choosing Double XL, you’re not just getting cleaner clothes, 
            you’re making an eco-conscious choice that helps protect our planet for future generations.
          </p>
        </div>

        {/* Dynamic Product Images */}
       

        {/* Fun Fact Section */}
        <div className="mt-12 bg-white text-black py-8 px-4 rounded-xl shadow-md">
          <h3 className="text-2xl font-bold text-indigo-600 mb-4">Did You Know?</h3>
          <p className="text-lg mb-4">
            Double XL Washing Powder’s formula is specially designed to not just clean, but protect your clothes, 
            leaving them looking brand new with every wash. The best part? It’s 100% eco-friendly and biodegradable.
          </p>
        </div>

        {/* Team Section */}
        <section className="mt-16 bg-gradient-to-r from-purple-500 to-pink-600 text-white py-12 px-12">
          <h2 className="text-3xl font-bold mb-6">Meet the Heroes Behind Double XL</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">

          <div className="text-center">
              <img src={img2} alt="Director" className="w-52 h-52 rounded-full mx-auto mb-4 border-4 border-white" />
              <h2 className="text-xl font-semibold mb-2 text-black">RAGHAV MISHRA</h2>
              <h3 className="text-xl font-semibold mb-2">Founder</h3>
              <p className="text-lg">Our Director brings years of industry expertise, ensuring that Double XL continues to innovate and meet the highest standards in both quality and sustainability.</p>
            </div> 

            <div className="text-center">
              <img src={img1} alt="Founder" className="w-52 h-52 rounded-full mx-auto mb-4 border-4 border-white" />
              <h2 className="text-xl font-semibold mb-2 text-black">SANJAY JAISWAL</h2>
              <h3 className="text-xl font-semibold mb-2">CMD</h3>
              <p className="text-lg">Our visionary Founder, who started the Double XL journey with the dream of creating a laundry solution that’s both powerful and planet-friendly.</p>
            </div>
            
            <div className="text-center">
            <img 
  src={img3} 
  alt="CEO" 
  className="w-52 h-52 object-cover rounded-full mx-auto mb-4 border-4 border-white scale-110"
/>

              <h2 className="text-xl font-semibold mb-2 text-black">Bhawani shankar sharma</h2>
              <h3 className="text-xl font-semibold mb-2">CEO</h3>
              <p className="text-lg">As CEO, our leader drives Double XL’s vision forward, making it a trusted name in every household while staying true to our environmental commitment.</p>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default AboutSection;
