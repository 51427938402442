import React from "react";
import Image from "./website banner 4.jpg";

const Navdown = () => {
  return (
    <div className="w-full  md:h-full">
      <img
        src={Image}
        className="w-full h-full object-cover"
        alt="Website Banner"
      />
    </div>
  );
};

export default Navdown;
