import React from "react";
import { FaHandsHelping, FaRecycle, FaSmile, FaTshirt } from "react-icons/fa";
import img from "../product/image/xxl.png";
import "./index.css";

const Company = () => {
  const products = [
    {
      title: "Double XL Washing Powder - 500 gm",
      description: "10 मिनट में जिद्दी दागों को साफ करें।",
      icon: <FaHandsHelping color="#4CAF50" />,
      weight: "500 gm",
    },
    {
      title: "Double XL Washing Powder - 1 kg",
      description: "हर धुलाई को बेहतर बनाए पावरफुल फार्मूला!",
      icon: <FaHandsHelping color="#FF5722" />,
      weight: "1 kg",
    },
    {
      title: "Double XL Washing Powder - 2.7 kg",
      description: "प्राकृतिक और पर्यावरण अनुकूल सामग्री।",
      icon: <FaRecycle color="#8BC34A" />,
      weight: "2.7 kg",
    },
    {
      title: "Double XL Washing Powder - 3 kg",
      description: "जल्दी असर करें और समय बचाएं।",
      icon: <FaSmile color="#FFC107" />,
      weight: "3 kg",
    },
    {
      title: "Double XL Washing Powder - 140 gm",
      description: "छोटा पैक, बड़ा असर!",
      icon: <FaTshirt color="#FF5722" />,
      weight: "140 gm",
    },
  ];

  return (
    <div className="bg-white from-teal-400 via-indigo-500 to-purple-600 py-16 overflow-hidden">
      <div className="w-full">
        <div className="flex items-center space-x-8 animate-scroll">
          {products.concat(products).map((product, index) => (
            // Duplicate products for seamless scrolling
            <div
              key={index}
              className="w-60 bg-white rounded-xl shadow-lg overflow-hidden flex-shrink-0 p-4 transform hover:-translate-y-2 transition-transform duration-300 ease-out"
            >
              {/* Product Image */}
              <div className="relative h-40 overflow-hidden mb-4">
                <img
                  src={img}
                  alt={product.weight}
                  className="w-full h-full object-cover"
                />
              </div>
              {/* Product Content */}
              <div className="text-center">
                <div className="text-3xl mb-2">{product.icon}</div>
                <h3 className="text-lg font-bold text-gray-800">
                  {product.title}
                </h3>
                <p className="text-sm text-gray-600">{product.description}</p>
                <p className="text-sm text-gray-700 font-medium mt-2">
                  Weight: {product.weight}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Company;
