import React from 'react';
import Typewriter from 'typewriter-effect';

const HomeBanner = () => {
  return (
    <div className="relative bg-white text-center p-6 sm:p-8 md:p-16 overflow-hidden">
      {/* Decorative Background Elements */}
      <div className="absolute inset-0 bg-white opacity-50 pointer-events-none"></div>
      <div className="absolute top-10 left-10 sm:left-20 w-20 h-20 sm:w-32 sm:h-32 bg-green-500 rounded-full blur-3xl opacity-30 animate-pulse"></div>
      <div className="absolute bottom-10 right-10 sm:right-20 w-24 h-24 sm:w-40 sm:h-40 bg-purple-500 rounded-full blur-3xl opacity-30 animate-pulse"></div>

      {/* Main Content */}
      <h1 className="text-3xl sm:text-4xl md:text-6xl font-extrabold text-blue-900 mb-6 animate-fadeIn">
        स्वागत है{' '}
        <span className="text-green-500 animate-jump">
          Double XL Washing Powder
        </span>{' '}
        में
      </h1>

      {/* Typing Effect */}
      <p className="text-base sm:text-lg md:text-2xl text-blue-900 mb-6">
        <Typewriter
          options={{
            strings: [
              'अत्यधिक दाग हटाने की शक्ति का अनुभव करें।',
              'कपड़ों पर नाजुक, गंदगी पर सख्त।',
              'Double XL – क्योंकि हर धोई जाने वाली चीज़ को सबसे अच्छा मिलना चाहिए!',
            ],
            autoStart: true,
            loop: true,
            delay: 50,
            deleteSpeed: 25,
          }}
        />
      </p>
    </div>
  );
};

export default HomeBanner;
