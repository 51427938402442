import React from 'react';

const AdditionalContent = () => {
  return (
    <div className="bg-white p-8 md:p-16 text-center">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">
        Why Choose Double XL Washing Powder?
      </h2>
      <p className="text-lg text-gray-700 mb-4">
        Double XL offers superior cleaning performance. With its advanced stain-removal formula, your clothes will look fresh and clean every time.
      </p>
      <p className="text-lg text-gray-700 mb-6">
        Whether it's everyday laundry or tough stains, Double XL ensures your clothes stay as good as new with every wash.
      </p>
      <button className="bg-green-500 text-white px-6 py-3 rounded-full hover:bg-green-600">
        Shop Now
      </button>
    </div>
  );
};

export default AdditionalContent;
